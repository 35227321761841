import cn from "classnames";

import styles from "./Socials.module.scss";

import { ReactComponent as Instagram } from "../../resources/icons/inst.svg";
import { ReactComponent as Vimeo } from "../../resources/icons/vimeo.svg";
import { ReactComponent as Linkedin } from "../../resources/icons/linkedin.svg";
import { ReactComponent as Notion } from "../../resources/icons/notion.svg";
import { HashLink as Link } from "react-router-hash-link";

const icons = [<Instagram />, <Vimeo />, <Linkedin />],
  texts = ["Instagram", "Vimeo", "Linkedin"];

const list = [
  "https://instagram.com/dazzzuniverse",
  "https://vimeo.com/user82690490",
  "https://www.linkedin.com/company/dazzzuniverse"
];

export const Socials = ({
  footer = false,
  sticky = false,
  careerPage = false,
  hoverDark = false,
  sideMenu = false,
}) => {
  return (
    <ul
      className={cn(
        styles.list,
        footer ? styles.footer : "",
        sticky ? styles.sticky : ""
      )}
    >
      {list.map((item, index) => {
        return (
          <li
            key={index}
            className={`${styles.item} ${
              hoverDark ? `${styles.hoverDark}` : ""
            }`}
          >
            <a
              href={item}
              className={styles.link}
              target="_blank"
              rel="nofollow noreferrer"
            >
              {icons[index]}
              <span className={styles.text}>{texts[index]}</span>
            </a>
          </li>
        );
      })}
      {sideMenu && (
        <li
          className={`${styles.item} ${hoverDark ? `${styles.hoverDark}` : ""}`}
        >
          <a
            href={"https://funky-aragon-33f.notion.site/DAZZZ-Career-Space-afe1d5f7416d463aad5e555b9bf3a03d?pvs=4"}
            className={styles.link}
            target="_blank"
            rel="nofollow noreferrer"
          >
            {<Notion />}
          </a>
        </li>
      )}
      {!footer && !sideMenu && (
        <li>
          <Link to={"/career"}>
            <div className={cn(styles.circle)}>
              <div
                className={cn(
                  styles.career,
                  careerPage ? styles.black : styles.white
                )}
              >
                CAREER
                <br />
                WITH
                <br />
                DAZZZ
              </div>
            </div>
          </Link>
        </li>
      )}
    </ul>
  );
};
