import logo from "../../resources/logo.svg";
import mobileLogo from "../../resources/icons/light-logo.png";
import { useMediaQuery } from "react-responsive";
import cn from "classnames";
import { HashLink as Link } from "react-router-hash-link";

import styles from "./Logo.module.scss";

export const Logo = ({ footer, careerPage }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  return careerPage ? (
    <Link to="/" className={cn(styles.logo, styles.invert)}>
      <img src={isMobile ? mobileLogo : logo} alt="Dazzzz Universe" />
    </Link>
  ) : (
    <div className={cn(styles.logo)}>
      <img src={isMobile ? mobileLogo : logo} alt="Dazzzz Universe" />
    </div>
  );
};
