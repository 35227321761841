import styles from './Portfolio.module.scss';
import {useState} from "react";
import {Modal} from "../Modal/Modal";

export const Portfolio = () => {
    const [show, setState] = useState(false);
    return (
        <>
            <div onClick={() => setState(!show)} className={styles.portfolio}>
                <div className={styles.text}>SHOW <span>PORTFOLIO</span></div>
            </div>

            <Modal video={true} open={show} closer={() => setState(!show)}>
                <div className={styles.wrapper}>
                    <div className={styles.iframe} style={{"padding":"56.25% 0 0 0","position":"relative"}}>
                        <iframe src="https://player.vimeo.com/video/766902685?h=fd5d07090a&autoplay=1&color=ff0179"
                                style={{"position":"absolute","top":0,"left":0,"width":"100%","height":"100%"}} frameBorder="0"
                                allow="fullscreen; picture-in-picture" title="Dazzzz" allowFullScreen/>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"/>
                </div>
            </Modal>
        </>
    )
}