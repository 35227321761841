import React from "react";
import styles from "./CareerPage.module.scss";
import arrow1 from "../../resources/arrow1.svg";
import arrow2 from "../../resources/arrow2.svg";

import mailLetter from "../../resources/icons/mailLetter.svg";
import notionLogo from "../../resources/icons/notion.svg";
import { Layout } from "../Layout/Layout";

export const CareerPage = ({ state, checkMenu, checkoutMenu, toTop }) => {
  return (
    <Layout
      careerPage
      hoverDark
      menu={state.menu}
      animation={state.animation}
      menuHandler={checkoutMenu}
      checker={checkMenu}
      firstSectionRef={null}
      invert
    >
      <div className={styles.body}>
        <div className={styles.main}>
          <h1 className={styles.header}>Career With Dazzz</h1>
          <div className={styles.text}>
            We’re looking for talents and professionals who will collaborate
            with us to implement groundbreaking ideas and create dazzling events
            <span className={styles.mobileOnly}>.</span>
            <br /> If you want to work at Dazzz, contact us via the application
            form.
            <br /> To learn more about the agency, our corporate culture, work
            approach and open vacancies, click the button below
            <span className={styles.mobileOnly}>.</span>
          </div>

          <div className={styles.links}>
            <a
              href="https://funky-aragon-33f.notion.site/DAZZZ-Career-Space-afe1d5f7416d463aad5e555b9bf3a03d?pvs=4"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <div className={`${styles.link} ${styles.toLeft}`}>
                <img className={styles.icon} src={notionLogo} alt="notion" />
                <div className={styles.linkText}>Vacancies</div>
              </div>
            </a>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSe2Bkps3ctw3WghF0l9NGg9OH3BQlA9PJ3Ldc9xV6y2YLlQyw/viewform"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <div className={styles.link}>
                <img className={styles.icon} src={mailLetter} alt="mail" />
                <div className={styles.linkText}>Apply</div>
              </div>
            </a>
          </div>
          <img className={styles.arrow1} src={arrow1} alt="decorative_arrow" />
          <img className={styles.arrow2} src={arrow2} alt="decorative_arrow" />
        </div>
      </div>
    </Layout>
  );
};
