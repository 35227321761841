import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './components';
import './styles/reset.scss';
import './styles/global.scss';
import 'animate.css/animate.min.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
        <App />
    // </React.StrictMode>
);
