import {  
  Video,
} from "../../components";
import React from "react";
import { scroller } from "react-scroll";
import cn from "classnames";
import { forwardRef } from "react";

import styles from "./Main.module.scss";

export const Main = forwardRef(function Main({ name }, ref) {
  const scrollTo = (name) => {
    scroller.scrollTo(name, {
      duration: 800,
      delay: 0,
      smooth: "easeInOut",
    });
  };

  return (
    <section name={name} className={cn("section", styles.main)} ref={ref}>
      <Video />
      <div className={styles.content}>
        <div className={styles.title}>
          WE INSPIRE BRANDS
          <br />
          BRANDS INSPIRE US
        </div>
      </div>
      <div className={styles.control} onClick={() => scrollTo("1")} />
    </section>
  );
});
