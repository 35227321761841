import cn from "classnames";
import { useEffect } from "react";
import { Modal } from "../../components";
import ScrollAnimation from "react-animate-on-scroll";

import styles from "./ContactUs.module.scss";
import { useState } from "react";
import { useInView } from "react-intersection-observer";

const mapsArray = [
  "25.12463965, 55.38045871956537&z=18",
  "43.243097250970614, 76.95201645578723&z=18",
  // "48.21824213804785, 16.37818413525018&z=18",
];

export const ContactUs = ({ name, setIsVisible }) => {
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(null);

  const { ref, inView } = useInView({
    rootMargin: "-50%",
    threshold: 0,
  });

  useEffect(() => {
    setIsVisible(inView);

    if (show) {
      setTimeout(() => {
        document
          .querySelector(".sizer")
          .insertAdjacentHTML(
            "beforeend",
            `<iframe src="https://maps.google.com/maps?q=${mapsArray[current]}&output=embed" title="Dazzzz Universe" width="100%" height="100%" frameBorder="0" style="border:0"/>`
          );
      }, 500);
    }
  }, [inView, current, setIsVisible, show]);

  const handler = (code) => {
    setCurrent(code);
    setShow(!show);
  };

  const arr = [
    {
      title: "DUBAI",
      address:
        "Unit 301-F, IFZA Properties, Dubai Digital Park, Building A2, Dubai Silicon Oasis",
      code: 0,
      map: 2,
    },
    // {
    //   title: "VIENNA",
    //   address: "Große Sperlgasse 31, 1020 Wien",
    //   phone: "+43 676 9016297",
    //   currentPhone: "+436769016297",
    //   code: 2,
    //   map: 5,
    // },
    {
      title: "ALMATY",
      address: "Almaty, Medeu District, Abay Ave, 23",
      code: 1,
      map: 3,
    },
  ];

  const maps = arr.map((item, index) => {
    const block = (
      <>
        <div className={styles.link}>
          <img
            src={`${process.env.PUBLIC_URL}/icons/icon-map.svg`}
            className={styles.icon}
            alt="icon-map"
          />
          <span>{`${item.address}`}</span>
        </div>
        {item.currentPhone && (
          <a href={`tel:${item.currentPhone}`} className={styles.link}>
            <img
              src={`${process.env.PUBLIC_URL}/icons/icon-phone.svg`}
              className={styles.icon2}
              alt='icon-phone'
            />
            <span>{`${item.phone}`}</span>
          </a>
        )}
      </>
    );

    const nameIn =
      index % 2 === 0 ? "animate__slideInLeft" : "animate__slideInRight";

    return (
      <ScrollAnimation
        key={index}
        className={styles.map}
        animateIn={nameIn}
        animateOnce={true}
      >
        <div className={styles.info}>
          <div className={styles.title}>{item.title}</div>
          <div className={styles.wrapper}>
            {block}
            {/* {type ? block : <div className={styles.link}>COMING SOON</div>}  */}
          </div>
          <ScrollAnimation
            animateIn={"animate__fadeIn"}
            delay={2000}
            className={styles.before}
          />
        </div>
        <div className={styles.block} onClick={() => handler(item.code)}>
          <img src={`${process.env.PUBLIC_URL}/maps/${index}.webp`} alt="" />
        </div>
        <div
          className={cn(styles.block, styles.mobile)}
          onClick={() => handler(item.code)}
        >
          <img src={`${process.env.PUBLIC_URL}/maps/${item.map}.webp`} alt="" />
        </div>
      </ScrollAnimation>
    );
  });

  return (
    <section
      ref={ref}
      name={name}
      className={cn("section", styles.contacts)}
      id={name}
    >
      {/* <Control text={'CONTACT US'}/>   */}
      <div className={styles.text}>
        To create a dazzling event, <span>contact us</span>
      </div>
      <a
        href={"mailto:welcome@dazzzuniverse.com"}
        className={cn(styles.link, styles.dazzmail)}
      >
        <span>{"welcome@dazzzuniverse.com".toUpperCase()}</span>
      </a>
      <div className={styles.maps}>
        {maps}
        <Modal open={show} closer={() => handler(current)}>
          <div className="sizer" />
        </Modal>
      </div>
      <div className={styles.design}>
        <div className={styles.slogan}>
          <div className={styles.dazzz}>
            <span>Let’s</span> DAZZZ <span>the</span> UNIVERSE
          </div>
        </div>
      </div>
    </section>
  );
};
