import {Burger} from "../Burger/Burger";
import cn from "classnames";

import styles from './Sticky.module.scss';
import logo from '../../resources/icons/sticky-logo.png';

export const Sticky = ({show, click, hidden}) => {
    return (
        <div className={cn(styles.sticky, show ? styles.active : '')}>
            <div className={styles.logo}>
                <img src={logo} alt="Dazzz"/>
            </div>
            <Burger hidden={hidden} sticky={true} click={click}/>
        </div>
    )
}