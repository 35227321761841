import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility'
import {useState} from "react";

export const Roll = () => {
    const words = ['Creativity & Brand Design • ', 'Exhibitions • ', '360° Marketing Communications • ', 'Influencer Marketing • ', 'Multilayered Events • ', 'Marketing Communications • '];

    const [pageIsVisible, setPageIsVisible] = useState(true);

    const handleVisibilityChange = (isVisible) => {
        setPageIsVisible(isVisible)
    }

    return (
        <PageVisibility onChange={handleVisibilityChange}>
            {pageIsVisible && (
                <Ticker>
                    {() => (
                        words.map((item,index) => <div key={index} className={index === 1 || index === 3 ? 'bold' : ''}>{item}</div>)
                    )}
                </Ticker>
            )}
        </PageVisibility>
    )
}