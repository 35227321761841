import styles from './Video.module.scss';

export const Video = () => {
    return (
        <div className={styles.video}>
            <div className={styles.wrap}>
                <video poster={`${process.env.PUBLIC_URL}/media/video.jpg`} autoPlay muted loop playsInline preload="metadata">
                    <source type="video/mp4" src={`${process.env.PUBLIC_URL}/media/video.mp4`}/>
                    <source type="video/webm" src={`${process.env.PUBLIC_URL}/media/video.webm`}/>
                </video>
            </div>
        </div>
    )
}