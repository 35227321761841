import styles from './Dots.module.scss';
import {scroller} from "react-scroll";
import cn from "classnames";

export const Dots = ({current, handler}) => {
    const scrollTo = (name) => {
        scroller.scrollTo(name, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOut'
        })
    }

    const list = [0, 1, 2, 3, 4].map((item) => {
        return <li data-dot={item} key={item} onClick={() => scrollTo(item)} className={cn(styles.item, current[item] ? styles.active : '')}/>;
    })
    return (
        <>
            <ul className={cn(styles.dots, current[4] ? styles.contacts : '')}>
                <div onClick={handler} className={cn(styles.top)}>TO TOP</div>
                {list}
            </ul>
        </>
    )
}