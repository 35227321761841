import React from "react";
import {
  Socials,
  Header,
  Sticky,
  Lang,
  Modal,
  Nav,
} from "../../components";
import { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Layout.module.scss";

export const Layout = ({
  children,
  careerPage,
  hoverDark,
  menu,
  animation,
  menuHandler,
  checker,
  firstSectionRef,
  navVariant,  
}) => {
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });

  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset >= elTopOffset + elHeight - 5) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  useEffect(() => {
    if (firstSectionRef?.current) {
      let header = firstSectionRef.current.getBoundingClientRect();
      const handleScrollEvent = () => {
        handleScroll(header.top, header.height);
      };
      window.addEventListener("scroll", handleScrollEvent);
      return () => {
        window.removeEventListener("scroll", handleScrollEvent);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Socials sticky careerPage={careerPage} hoverDark={hoverDark} />
      <Header click={menuHandler} careerPage={careerPage}/>
      <Sticky click={menuHandler} hidden={menu} show={sticky.isSticky} />
      <Modal className="no-close" open={menu} closer={menuHandler}>
        <div className={cn(styles.menu, animation ? styles.active : "")}>
          <div className={styles.close} onClick={menuHandler} />
          <div className={styles.wrap}>
            <Nav handler={checker} variant={navVariant}/>
            <Lang />
          </div>
          <Socials sideMenu/>
        </div>
      </Modal>
      {children}
    </div>
  );
};
