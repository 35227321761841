import { Control, Portfolio, Roll } from "../../components";
import cn from "classnames";

import styles from "./WhatWeDo.module.scss";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

export const WhatWeDo = ({ name, setIsVisible }) => {
  const { ref, inView } = useInView({
    rootMargin: "-50%",
    threshold: 0,
  });

  useEffect(() => {
    setIsVisible(inView);
  }, [inView, setIsVisible]);

  return (
    <section
      ref={ref}
      name={name}
      className={cn("section", styles.what)}
      id={name}
    >
      <Control text={"WHAT WE DO"} />
      <div className={cn("text", styles.desc)}>
        DAZZZ UNIVERSE is about bold decisions and close attention to the
        smallest details, inventing new meanings while respecting guidelines,
        getting to the core of the challenge while creating large-scale ideas.
        We provide full range of business solutions a brand needs to stand out.
      </div>
      <div name="5" id="5" className={styles.wrapper}>
        <Roll />
        <Portfolio />
      </div>
    </section>
  );
};
