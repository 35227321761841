import ReactModal from 'react-modal';
import cn from "classnames";
ReactModal.setAppElement('#root');

export const Modal = ({open, closer, children, className}) => {
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closer}
            overlayClassName="overlay"
            className={cn("modal", className)}>
            <div className="close" onClick={closer}/>
            {children}
        </ReactModal>
    )
}