import cn from "classnames";

import styles from "./Burger.module.scss";

export const Burger = ({ click, sticky = false }) => {
  return (
    <div
      className={cn(styles.burger, sticky ? styles.sticky : "")}
      onClick={click}
    />
  );
};
