import { Control } from "../../components";
import cn from "classnames";

import styles from "./OurServices.module.scss";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const OurServices = ({ name, setIsVisible }) => {
  const { ref, inView } = useInView({
    rootMargin: "-50%",
    threshold: 0,
  });

  useEffect(() => {
    setIsVisible(inView);
  }, [inView, setIsVisible]);

  const text = [
    {
      title: "DAZZZ.STUDIO",
      text: "Event . Show . Exhibition . Pop up . Art installations Design . Сoncept Development . Creative Strategy . Guidelines Creation and Adaptation . Event Scenography . Sketches and 3D Visuals . Consulting & Expertise  . Art Direction",
    },
    {
      title: "DAZZZ.PRODUCTION",
      text: "Event Management . Event Planing . Entertainment Programme . Performance & Show Production . Decor full circle . Technical support . Print & Gifts . Photo & Video . Guest list management",
    },
    {
      title: "DAZZZ.DIGITAL",
      text: "Online Projects . Digital Campaigns . Social Media . Influencer Campaigns & Takeovers . Website & E-commerce development . VR/AR performance . Metaverse performance",
    },
    {
      title: "DAZZZ.EDUCATION",
      text: "Online & Offline Social & Educational conference . Unique Education formats & Programms . Course about Event & Show Design in HSE  Design school",
    },
    {
      title: "DAZZZ.COMMERCIAL",
      text: "Brand Commercial . Product video . Product launch . Cultural & Education films . Social video . Music video . 3D graphics",
    },
    {
      title: "DAZZZ.PROMOTION",
      text: "Brand Vision . Brand & Product Launch . Partnerships . Retail Strategy . Campaign Strategy . Consumer Journey . Brand Consulting . Brand Identity . Brand Design",
    },
    {
      title: "DAZZZ.TALENT",
      text: "Cultural Programs development . Collabotations Strategy . Celebrities partnerships . Young Art & Design talent producing",
    },
  ];

  const info = text.map((item, index) => {
    return (
      <ScrollAnimation
        key={index}
        animateIn="animate__backInUp"
        animateOnce={true}
        className={styles.item}
      >
        <div className={styles.title}>{item.title}</div>
        <div className={styles.text}>{item.text}</div>
      </ScrollAnimation>
    );
  });

  return (
    <section ref={ref} name={name} className={cn("section", styles.services)} id={name}>
      <Control text={"OUR SERVICES"} />

      <ul className={styles.list}>{info}</ul>
    </section>
  );
};
