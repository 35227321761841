import {Burger, Logo} from "../../components";

import styles from './Header.module.scss';

export const Header = ({click, careerPage} ) => {
    return (
        <div className={styles.header}>
            <Logo careerPage={careerPage}/>
            <Burger click={click}/>
        </div>
    )
}