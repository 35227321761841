import cn from "classnames";
import { scroller } from "react-scroll";
import styles from "./Nav.module.scss";
import { HashLink as Link } from "react-router-hash-link";

export const Nav = ({ handler, footer = false, variant }) => {
  const scrollTo = (name) => {
    handler();
    scroller.scrollTo(name, {
      duration: 800,
      delay: 0,
      smooth: "easeInOut",
    });
  };

  const arr = [
    {
      text: "WHO WE ARE",
      link: 1,
    },
    {
      text: "WHAT WE DO",
      link: 2,
    },
    {
      text: "OUR SERVICES",
      link: 3,
    },
    {
      text: "PORTFOLIO",
      link: 5,
    },
    {
      text: "CONTACT US",
      link: 4,
    },
  ];

  const list = arr.map((item, index) => {
    return variant === "currentPage" ? (
      <div
        key={index}
        className={styles.link}
        onClick={() => scrollTo(item.link)}
      >
        {item.text}
      </div>
    ) : (
      <Link
        key={index}
        className={styles.link}
        to={`/#${item.link}`}
        onClick={handler}
      >
        {item.text}
      </Link>
    );
  });

  return (
    <div className={cn(styles.list, footer ? styles.footer : "")}>
      {list}
      <Link to={"/career"} className={styles.link} onClick={handler}>
        CAREER WITH DAZZZ
      </Link>
    </div>
  );
};
