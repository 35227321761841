import { Control } from "../../components";
import cn from "classnames";

import styles from "./WhoWeAre.module.scss";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const WhoWeAre = ({ name, setIsVisible }) => {
  const { ref, inView } = useInView({
    rootMargin: "-50%",
    threshold: 0,
  });

  useEffect(() => {
    setIsVisible(inView);
  }, [inView, setIsVisible]);

  const text = [
    {
      title: "VISIONARIES",
      text: "Victoria and Miroslava — visionary duo, lifelong friends and trusted experts with over 20 years of experience in event management, production and communications.",
    },
    {
      title: "LUXURY EXPERTS",
      text: "We offer bespoke, innovative and integrated solutions for the leading brands in fine jewelry, fashion, beauty, lifestyle and hospitality.",
    },
    {
      title: "PERFECTIONISTS",
      text: "Our expertise and credibility in brand-building, design, marketing communications, events and production allows us to take on most challenging and sophisticated  projects and turn them into state-of-the-art results.",
    },
    {
      title: "WORLDWIDE TEAM",
      text: "DAZZLING boasts a high-end global team of experts performing in Dubai, Vienna and Moscow offices, who are committed to deliver best-in-class solutions to our partners",
    },
  ];

  const info = text.map((item, index) => {
    const nameIn =
      index % 2 === 0 ? "animate__slideInLeft" : "animate__slideInRight";
    return (
      <div key={index} className={styles.item}>
        <ScrollAnimation
          className={styles.test}
          animateIn={nameIn}
          animateOnce={true}
        >
          <div className={styles.title}>{item.title}</div>
          <div className={cn("text", styles.text)}>{item.text}</div>
        </ScrollAnimation>
        <div className={styles.before} />
      </div>
    );
  });

  return (
    <section ref={ref} name={name} className={cn("section", styles.who)} id={name}>
      <Control text={"WHO WE ARE"} />

      <div className={cn("text", styles.desc)}>
        DAZZZ UNIVERSE is the new era of the renowned DAZZLING creative agency
        launched in 2018 by Victoria Motornyuk and Miroslava Bayda.
      </div>
      <ul className={styles.list}>{info}</ul>
    </section>
  );
};
