import { Component } from "react";
import { MainPage, CareerPage } from "../../pages";
import { Preloader } from "../../components";
import { animateScroll as scroll, scroller } from "react-scroll";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import icon from "../../resources/logo.svg";
import darkLogo from "../../resources/icons/dark-logo.png";
import lightLogo from "../../resources/icons/light-logo.png";

// удалить лишнее
import finger from "../../resources/icons/finger.svg";
import logo from "../../resources/icons/logo.png";
import close from "../../resources/icons/close.svg";

import stickyLogo from "../../resources/icons/sticky-logo.png";

export class App extends Component {
  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  state = {
    loading: true,
    show: false,
    menu: false,
    animation: false,
  };

  toggleState = (name) => {
    this.setState({
      [name]: !this.state[`${name}`],
    });
  };

  checkoutMenu = () => {
    if (!this.state.menu) {
      this.toggleState("menu");
      document.body.style.marginRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
      setTimeout(() => {
        this.toggleState("animation");
      }, 0);
    } else {
      this.toggleState("animation");
      setTimeout(() => {
        document.body.style.marginRight = "0px";
        this.toggleState("menu");
      }, 600);
    }
  };

  checkMenu(state) {
    if (state) {
      this.toggleState("menu");
      this.toggleState("animation");
      document.body.style.marginRight = "0px";
    }
  }

  componentDidMount() {
    let images = [
      `${process.env.PUBLIC_URL}/media/video.jpg`,
      icon,
      finger,
      logo,
      close,
      darkLogo,
      lightLogo,
      stickyLogo,
    ];
    [0, 1, 2, 3].map((item, index) =>
      images.push(`${process.env.PUBLIC_URL}/maps/${index}.webp`)
    );

    let promises = [];

    images.forEach((image) => {
      promises.push(
        new Promise((resolve, reject) => {
          const newImage = new Image();

          newImage.addEventListener("load", function () {
            resolve();
          });

          newImage.src = image;
          window[image] = newImage;
        })
      );
    });

    Promise.all(promises).then(() => {
      setTimeout(() => {
        this.toggleState("loading");
        setTimeout(() => {
          this.toggleState("show");

          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty("--vh", `${vh}px`);
        }, 0);
      }, 2100);
    });
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  scrollTo(name) {
    scroller.scrollTo(name, {
      duration: 1500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  render() {
    return (
      <div className="wrapper">
        {this.state.loading ? (
          <Preloader />
        ) : (
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <MainPage
                    state={this.state}
                    toTop={this.scrollToTop}
                    checkMenu={() => this.checkMenu(this.state.menu)}
                    checkoutMenu={this.checkoutMenu}
                  />
                }
              />
              <Route
                path="/career"
                element={
                  <CareerPage
                    state={this.state}
                    toTop={this.scrollToTop}
                    checkMenu={() => this.checkMenu(this.state.menu)}
                    checkoutMenu={this.checkoutMenu}
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        )}
      </div>
    );
  }
}
