import {Socials} from "../../components";

import styles from "./Footer.module.scss";

export const Footer = ({toTop}) => {
    return (
        <div className={styles.footer}>
            <div className={styles.top} onClick={toTop}>TO TOP</div>
            <div className={styles.wrapper}>
                <div className={styles.text}>GET CONNECTED WITH US ON</div>
                <Socials footer={true}/>
            </div>
        </div>
    )
}