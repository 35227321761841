import React, { useState } from "react";
import cn from "classnames";
import styles from "./MainPage.module.scss";
import { Dots, Footer } from "../../components";
import { ContactUs } from "../ContactUs/ContactUs";
import { Main } from "../Main/Main";
import { OurServices } from "../OurServices/OurServices";
import { WhatWeDo } from "../WhatWeDo/WhatWeDo";
import { WhoWeAre } from "../WhoWeAre/WhoWeAre";
import { Layout } from "../Layout/Layout";
import { useRef } from "react";

export const MainPage = ({ state, checkMenu, checkoutMenu, toTop }) => {
  const [who, setWho] = useState(false);
  const [what, setWhat] = useState(false);
  const [our, setOur] = useState(false);
  const [cont, setCont] = useState(false);
  const firstSectionRef = useRef(null);

  return (
    <Layout
      menu={state.menu}
      animation={state.animation}
      menuHandler={checkoutMenu}
      checker={checkMenu}
      firstSectionRef={firstSectionRef}
      navVariant={"currentPage"}
    >
      <div className={cn(styles.mainPage, state.show ? styles.active : "")}>
        <Dots
          handler={toTop}
          current={[!(who || what || our || cont), who, what, our, cont]}
        />
        <Main name="0" ref={firstSectionRef} />
        <WhoWeAre setIsVisible={setWho} name="1" />
        <WhatWeDo setIsVisible={setWhat} name="2" />
        <OurServices setIsVisible={setOur} name="3" />
        <ContactUs setIsVisible={setCont} name="4" />
        <Footer toTop={toTop} handler={checkMenu} />
      </div>
    </Layout>
  );
};
